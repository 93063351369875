import { Injectable } from '@angular/core';
import {
  appendVisibilitySettings,
  ChipRendererComponent,
  ChipRendererConfig,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  DateRendererConfig,
  OrganizationType,
  RendererUtilService,
  ResourcesService,
  SearchSelectFilterComponent,
  SelectFilterComponent,
  SelectFilterOption,
  TextFilterComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { VerificationStatusEnum } from '@gea-id/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsColumnDefinitionsService {
  readonly ORGANIZATION_PREFIX = 'ORGANIZATIONS.LIST.';
  readonly ORGANIZATION_STATUS_PREFIX = 'ORGANIZATIONS.LIST.STATUS.';

  organizationColumnDefinitions$: Observable<ColumnDefinition[]> = this.resourcesService.getCountries().pipe(
    map((countries) =>
      appendVisibilitySettings([
        {
          displayName: 'X.LABEL.NAME',
          key: 'name',
          width: 300,
          filter: {
            component: TextFilterComponent,
          },
        },
        {
          displayName: this.ORGANIZATION_PREFIX + 'USER_COUNT',
          key: 'userCount',
          sortable: false,
        },
        {
          displayName: 'X.LABEL.TYPE',
          key: 'type',
          filter: {
            component: SelectFilterComponent,
            config: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              options: this.filterUnknownOptions(),
            },
          },
          renderer: {
            component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
            config: {
              prefix: 'X.ORGA_TYPE.',
            },
          },
        },
        {
          displayName: 'X.ROLE.OWNER',
          key: 'owner',
          sortable: false,
        },
        {
          displayName: this.ORGANIZATION_PREFIX + 'CUSTOMER_NUMBER',
          key: 'customerNumber',
          filter: {
            component: TextFilterComponent,
          },
        },
        {
          displayName: this.ORGANIZATION_PREFIX + 'STATUS.HEADER',
          key: 'verificationStatus',
          sortable: true,
          filter: {
            component: SelectFilterComponent,
            config: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
              options: this.rendererUtilService.getAllEnumValuesAsSelectFilterOptions(
                VerificationStatusEnum,
                this.ORGANIZATION_STATUS_PREFIX
              ),
            },
          },
          renderer: {
            component: ChipRendererComponent as ColumnRendererComponent<unknown>,
            config: {
              mapping: {
                VERIFIED: {
                  severity: 'success',
                  text: this.ORGANIZATION_PREFIX + 'STATUS.VERIFIED',
                },
                NOT_REQUESTED: {
                  severity: 'default',
                  text: this.ORGANIZATION_PREFIX + 'STATUS.NOT_REQUESTED',
                },
                PENDING: {
                  severity: 'information',
                  text: this.ORGANIZATION_PREFIX + 'STATUS.PENDING',
                },
              },
            } as ChipRendererConfig,
          },
        },
        this.createDateTimeColumn(this.ORGANIZATION_PREFIX + 'VERIFICATION_REQUEST_DATE', 'verificationRequestTime'),
        {
          displayName: 'X.LABEL.COUNTRY',
          key: 'address.country',
          filter: {
            component: SearchSelectFilterComponent,
            config: {
              options: [...countries],
            },
          },
          renderer: {
            component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
            config: {
              prefix: 'COUNTRIES.',
            },
          },
          sortable: false,
        },
        {
          displayName: 'X.LABEL.CITY',
          key: 'address.city',
          filter: {
            component: TextFilterComponent,
          },
        },
        {
          displayName: 'X.LABEL.ZIP',
          key: 'address.zipCode',
          filter: {
            component: TextFilterComponent,
          },
        },
        {
          displayName: 'X.LABEL.STREET',
          key: 'address.street',
          filter: {
            component: TextFilterComponent,
          },
        },
        this.createDateTimeColumn(this.ORGANIZATION_PREFIX + 'CREATED_AT', 'createdAt'),
        this.createDateTimeColumn('X.LABEL.LAST_MODIFIED_DATE', 'lastModifiedAt'),
      ])
    )
  );

  constructor(
    private rendererUtilService: RendererUtilService,
    private resourcesService: ResourcesService,
    private translateService: TranslateService
  ) {}

  createTextColumn(displayName: string, key: string, filterable = false): ColumnDefinition {
    return {
      displayName,
      key,
      filter: filterable
        ? {
            component: TextFilterComponent,
          }
        : undefined,
      visible: true,
    };
  }

  createDateTimeColumn(displayName: string, key: string): ColumnDefinition {
    return {
      displayName,
      key,
      visible: true,
      sortable: true,
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'dd.MM.yyyy HH:mm:ss',
        } as DateRendererConfig,
      },
    };
  }

  filterUnknownOptions(): SelectFilterOption[] {
    const options = this.rendererUtilService.getAllEnumValuesAsSelectFilterOptions(OrganizationType, this.ORGANIZATION_PREFIX);
    return options.filter((option) => option.value !== 'UNKNOWN');
  }
}
