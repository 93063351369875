import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  MembershipsListOrganization200ResponseInner,
  OrganizationPutRequest,
  OrganizationResponse,
  OrganizationsApiService,
  OrganizationsList200Response,
  OrganizationsListRequestParams,
} from '../../generated/api/v1';
import { extractRequestParams, FilterTableSettings } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  MULTI_SELECT_PARAMS: (keyof OrganizationsListRequestParams)[] = [
    'typeContains',
    'verificationStatusContains',
    'countryContains',
  ];
  constructor(private apiService: OrganizationsApiService) {}

  getOrganizationsV2(filter: FilterTableSettings): Observable<OrganizationsList200Response> {
    const mapping: { [key: string]: keyof OrganizationsListRequestParams } = {
      name: 'nameContains',
      customerNumber: 'customerNumberContains',
      'address.street': 'streetContains',
      'address.city': 'cityContains',
      'address.zipCode': 'zipCodeContains',
      'address.country': 'countryContains',
      type: 'typeContains',
      verificationStatus: 'verificationStatusContains',
    };
    const requestParams = extractRequestParams(filter, mapping, this.MULTI_SELECT_PARAMS);

    return this.apiService.organizationsList(requestParams);
  }

  deleteOrga(orgaId: string) {
    return this.apiService.organizationsDelete({ orgaId });
  }

  getOrgaDetails(orgaId: string): Observable<OrganizationResponse> {
    return this.apiService.organizationsGet({ orgaId: orgaId }).pipe(map((orga) => ({ ...orga, type: orga.type })));
  }

  getOrgaMemberships(orgaId: string): Observable<MembershipsListOrganization200ResponseInner[]> {
    return this.apiService.membershipsListOrganization({ orgaId: orgaId });
  }

  updateOrga(orgaId: string, orgaData: OrganizationPutRequest): Observable<unknown> {
    return this.apiService.organizationsUpdate({ orgaId, organizationPutRequest: orgaData });
  }

  createOrga(orgaData: OrganizationPutRequest): Observable<OrganizationResponse> {
    return this.apiService.organizationsCreate({ organizationPostRequest: orgaData });
  }

  requestOrganizationVerification(): Observable<void> {
    return this.apiService.organizationsRequestVerification() as Observable<void>;
  }
}
