import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionKey, PermissionsState, UserState } from '@gea/digital-ui-lib';
import { AboutGeaComponent } from './about-gea/about-gea.component';
import { AdministrationTasksComponent } from './administration-tasks';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MdgCustomerTableComponent } from './mdg-customer-table';
import { TemporaryMembershipComponent } from './temporary-membership';
import { FeatureFlagsComponent } from './feature-flags/list/feature-flags.component';
import { UsersListPageComponent } from './users-list/users-list-page.component';
import { MembershipsListPageComponent } from './users-list/user-detail/memberships-list/memberships-list-page.component';
import { OrganizationsListPageComponent } from './organizations/organizations-list-page/organizations-list-page.component';
import { UserDetailComponent } from './users-list/user-detail/user-detail.component';
import { EditUserFormComponent } from './users-list/user-detail/edit-user-form/edit-user-form.component';
import { UserHistoryListComponent } from './users-list/user-detail/user-history-list/user-history-list.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { OrganizationDetailComponent } from './organizations/organization-detail/organization-detail.component';
import { OrganizationInfoComponent } from './organizations/organization-detail/organization-info/organization-info.component';
import { OrganizationUsersComponent } from './organizations/organization-detail/organization-users/organization-users.component';
import { OrganizationHistoryComponent } from './organizations/organization-detail/organization-history/organization-history.component';
import { OrganizationBusinessRelationsComponent } from './organizations/organization-detail/organization-business-relations/organization-business-relations.component';
import { OrganizationSitesComponent } from './organizations/organization-detail/organization-business-sites/organization-sites.component';
import { orgaIdGuards, orgaRoutes } from './organizations/models/organizations.routing';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInviteComponent } from './users-list/user-invite/user-invite.component';
import { DiscardChangesGuard } from '@gea-id/shared';
import { GeaUserListComponent } from './gea-user-list/gea-user-list.component';
import { ApplicationsComponent } from './applications/list/applications.component';
import { ApplicationDetailComponent } from './applications/application-detail/application-detail.component';
import { appIdGuards, appRoutes } from './applications/models/applications.routing';
import { ApplicationInfoComponent } from './applications/application-detail/application-info/application-info.component';
import { ApplicationAssetsComponent } from './applications/application-detail/application-assets/application-assets.component';
import { AssetItemComponent } from './applications/application-detail/application-assets/asset-item/asset-item.component';

const routes: Routes = [
  {
    // Needed for hash routing
    path: 'error',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'state',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'code',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'user',
    component: UsersListPageComponent,
    data: { isAdmin: true },
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'organization',
    component: OrganizationsListPageComponent,
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'user-history/:userId',
    component: UserHistoryListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'temporary-membership',
    component: TemporaryMembershipComponent,
    canActivate: [MsalGuard],
  },

  {
    path: 'administration-tasks',
    component: AdministrationTasksComponent,
    canActivate: [MsalGuard],
  },

  {
    // Needed for hash routing
    path: 'about-gea',
    component: AboutGeaComponent,
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    // Needed for hash routing
    path: 'mdg-customer',
    component: MdgCustomerTableComponent,
    data: { isAdmin: true },
    canActivate: [MsalGuard],
  },
  {
    path: 'feature-flags-assignment',
    component: FeatureFlagsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [
      MsalGuard,
      () => {
        const router = inject(Router);
        return inject(Store)
          .select(UserState.tokenObject)
          .pipe(
            map((token) => {
              const scopes = token?.extension_admin_scopes;
              if (scopes) {
                return Object.values(scopes).some((entry) => entry.name === 'Admin_Manage_AppRegistration');
              }
              return router.navigate(['/dashboard']);
            })
          );
      },
    ],
  },
  {
    path: 'user/user-invite',
    component: UserInviteComponent,
    canActivate: [MsalGuard],
    canDeactivate: [DiscardChangesGuard],
  },
  {
    path: 'user/:userId',
    component: UserDetailComponent,
    canActivate: [MsalGuard],
    canDeactivate: [DiscardChangesGuard],
    children: [
      {
        path: 'edit-user',
        component: EditUserFormComponent,
        canActivate: [
          MsalGuard,
          () => {
            return inject(Store)
              .select(PermissionsState.userPermissions)
              .pipe(
                filter((x) => !!x.length), // Assumption, we should never have empty Permissions
                map(
                  (permissions) =>
                    permissions.includes(PermissionKey.CREATE_USER_INVITATION) || permissions.includes(PermissionKey.UPDATE_USER)
                )
              );
          },
        ],
      },
      {
        path: 'memberships',
        component: MembershipsListPageComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'user-history',
        component: UserHistoryListComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: 'organization/:orgaId',
    component: OrganizationDetailComponent,
    canActivate: [MsalGuard, orgaIdGuards.isValid],
    children: [
      {
        path: orgaRoutes.BASIC_INFO,
        component: OrganizationInfoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: orgaRoutes.USERS,
        component: OrganizationUsersComponent,
        canActivate: [MsalGuard, orgaIdGuards.isNotNew],
      },
      {
        path: orgaRoutes.HISTORY,
        component: OrganizationHistoryComponent,
        canActivate: [MsalGuard, orgaIdGuards.isNotNew],
      },
      {
        path: orgaRoutes.BUSINESS_RELATIONS,
        component: OrganizationBusinessRelationsComponent,
        canActivate: [MsalGuard, orgaIdGuards.isNotNew],
      },
      {
        path: orgaRoutes.SITES,
        component: OrganizationSitesComponent,
        canActivate: [MsalGuard, orgaIdGuards.isNotNew],
      },
    ],
  },
  {
    path: 'send-notification',
    component: SendNotificationComponent,
    canActivate: [
      MsalGuard,
      () => {
        return inject(Store)
          .select(PermissionsState.userPermissions)
          .pipe(
            filter((x) => !!x.length), // Assumption, we should never have empty Permissions
            map((permissions) => permissions.includes(PermissionKey.CONTACT_USER_WITH_NOTIFICATION))
          );
      },
    ],
  },
  {
    path: 'gea-user',
    component: GeaUserListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'applications/:appId',
    component: ApplicationDetailComponent,
    canActivate: [MsalGuard, appIdGuards.isValid],
    children: [
      {
        path: appRoutes.BASIC_INFO,
        component: ApplicationInfoComponent,
        canActivate: [MsalGuard],
      },
      {
        path: appRoutes.ASSETS,
        component: ApplicationAssetsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: appRoutes.ASSETS + '/:assetId',
        component: AssetItemComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      // Don't perform initial navigation in iframes
      initialNavigation: isIframe ? 'disabled' : 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
