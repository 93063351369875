<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="APPLICATIONS_REGISTRATION.TAB_TITLE"
      help="HELP.APPLICATIONS_REGISTRATION.PAGE">
      <div class="page-header-content">
        <geaui-button-v2
          [sending]="false"
          [loading]="loading"
          [inverted]="false"
          (click)="openAdd()"
          preIcon="16px_e-add"
          routerLinkActive="active">
          {{ 'APPLICATIONS_REGISTRATION.ADD.BUTTON.LABEL' | translate }}
        </geaui-button-v2>
      </div>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="application-list-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="TABLE_ID"
        [data]="(applicationsData$ | async) ?? []"
        [columnDefinitions]="columnDefinitions"
        [sortable]="true"
        [totalRecords]="(totalRecords$ | async) ?? 0"
        [rowsClickable]="true"
        [loading]="loading"
        (rowClicked)="edit($event)">
      </geaui-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
