import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { App, ApiService } from '@gea/digital-ui-lib';
import { MsalBroadcastService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AppService extends ApiService {
  appLibraryUrl = 'registration-service/v1/apps';
  apiVersion = '1';

  constructor(
    @Inject('registrationBaseURL') protected override baseUrl: string,
    @Inject('subscriptionKey') protected override subscriptionKey: string,
    protected override msalBroadcast: MsalBroadcastService,
    protected override http: HttpClient,
    protected override store: Store,
    private api: ApiService
  ) {
    super(baseUrl, subscriptionKey, msalBroadcast, http, store);
  }

  getApps(): Observable<App[]> {
    return this.api.get<App[]>(this.appLibraryUrl, {}, this.apiVersion);
  }
  getTempAccessApps(): Observable<App[]> {
    return this.api.get<App[]>(this.appLibraryUrl + '/temp', {}, this.apiVersion);
  }
}
